<template>
      <div class="section" id="contact">
        <div class="cc-contact-information" style="background-image: url('../assets/images/staticmap.png')">
          <div class="container">
            <div class="cc-contact">
              <div class="row">
                <div class="col-md-9">
                  <div class="card mb-0" data-aos="zoom-in">
                    <div class="h4 text-center title">Küldj üzenetet</div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="card-body">
                          <form action="https://formspree.io/f/xpzbrlwd" method="POST">
                            <div class="p pb-3"><strong>Keress bizalommal </strong></div>
                            <div class="row mb-3">
                              <div class="col">
                                <div class="input-group"><span class="input-group-addon"><i class="fa fa-user-circle"></i></span>
                                  <input class="form-control" type="text" name="name" placeholder="Name" required="required"/>
                                </div>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col">
                                <div class="input-group"><span class="input-group-addon"><i class="fa fa-file-text"></i></span>
                                  <input class="form-control" type="text" name="Subject" placeholder="Subject" required="required"/>
                                </div>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col">
                                <div class="input-group"><span class="input-group-addon"><i class="fa fa-envelope"></i></span>
                                  <input class="form-control" type="email" name="_replyto" placeholder="E-mail" required="required"/>
                                </div>
                              </div>
                            </div>
                            <div class="row mb-3">
                              <div class="col">
                                <div class="form-group">
                                  <textarea class="form-control" name="message" placeholder="Your Message" required="required"></textarea>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col">
                                <button class="btn btn-primary" type="submit">Küldés</button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="card-body">
                          <p class="mb-0"><strong>Cím </strong></p>
                          <p class="pb-2">1089 Budapest Sárkány utca 14</p>
                          <p class="mb-0"><strong>Mobil</strong></p>
                          <p class="pb-2">+36 30 737 1383</p>
                          <p class="mb-0"><strong>Email</strong></p>
                          <p>zsolti0214@gmail.com</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>

export default {
  name: 'Contact',

}
</script>
